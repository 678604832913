<script>
import { required } from "vuelidate/lib/validators";
import axios from 'axios';
import Swal from "sweetalert2";

export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      submitted: false,
      keyup : false,
    };
  },
  validations: {
    oldPassword: { required },
    newPassword: { required },
    confirmNewPassword: { required },
  },
  methods: {
    submit() {
        let idUser = JSON.parse(localStorage.getItem("user")).user_id;
        let admin = JSON.parse(localStorage.getItem("user")).id;
        console.log(admin)
        let data = { 
            old_password: this.oldPassword,
            new_password: this.newPassword,
            admin: admin,
        }
        axios({
            method: "PUT",
            url: `/v1/changepassword/${idUser}`,
            data: data,
        })
        .then((res) => {
            if(res.data.success){
                Swal.fire("Success", res.data.message, "success");
                this.$router.push('/home')
            } else {
                 Swal.fire("Error", res.data.message, "error");
            }
        })
    },
    validat(){
        this.keyup = true
    }
  }
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div class="login-box">
                      <div class="text-center">
                        <div>
                          <div class="logo">
                            <img src="@/assets/images/logo-2.png" height="50" alt="logo" />
                          </div>
                        </div>

                        <h4 class="font-size-18 mt-4">Change Password</h4>
                        <!-- <p class="text-muted">Sign in</p> -->
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="submit">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Current Password</label>
                            <input
                              v-model="oldPassword"
                              password-reveal
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Your password"
                              :class="{ 'is-invalid': submitted && $v.oldPassword.$error }"
                            />
                            <div
                              v-if="submitted && !$v.oldPassword.required"
                              class="invalid-feedback"
                            >Current Password is required.</div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword1" style="color:red" v-if="newPassword !== confirmNewPassword && keyup">New Password</label>
                            <label for="userpassword1" v-else>New Password</label>
                            <input
                              v-model="newPassword"
                              type="password"
                              class="form-control"
                              id="userpassword1"
                              placeholder="Enter new password"
                              :class="{ 'is-invalid': submitted && $v.newPassword.$error }"
                            />
                            <div
                              v-if="submitted && !$v.newPassword.required"
                              class="invalid-feedback"
                            >New Password is required.</div>
                          </div>
                          
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword2" style="color:red" v-if="newPassword !== confirmNewPassword && keyup">Confirm New Password</label>
                            <label for="userpassword2" v-else>Confirm New Password</label>
                            <input
                              v-model="confirmNewPassword"
                              @keyup="validat"
                              type="password"
                              class="form-control"
                              id="userpassword2"
                              placeholder="Confirm your new password"
                              :class="{ 'is-invalid': submitted && $v.confirmNewPassword.$error }"
                            />
                            <div
                              v-if="submitted && !$v.confirmNewPassword.required"
                              class="invalid-feedback"
                            >Confirm Password is required.</div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              :disabled="
                                oldPassword &&
                                    newPassword &&
                                    confirmNewPassword &&
                                    newPassword !== confirmNewPassword
                                "
                            >Submit</button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link tag="a" to="/" class="text-muted">
                              <i class="ri-apps-fill"></i> Back to Dashboard
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © 2022 Adaremit
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>